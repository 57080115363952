import React from 'react'
import cx from 'classnames'
import { prepareParagraph } from '../../../utils/format'
import styles from './style.module.sass'

export default ({ table }) => {
  if (!table || table.isHide) return null

  const getIconByValue = column => {
    switch (column) {
      case 'plus':
        return <i className={styles.iconPlus} />
      case 'question':
        return <i className={styles.iconQuestion}>?</i>
      case 'minus':
        return <i className={styles.iconMinus} />
    }
  }

  return (
    <div className={styles.table}>
      <div className={styles.wrap}>
        <h2 className={cx(styles.title, styles.titleMobile)}>{table.title}</h2>
        <table className={styles.tableInside}>
          <thead className={styles.row}>
            <tr>
              <td className={styles.cell}>
                <h2 className={cx(styles.title, styles.titleDesktop)}>{table.title}</h2>
              </td>
              <td className={cx(styles.cell, styles.cellAlign)}>
                <div dangerouslySetInnerHTML={{ __html: table.title_column_1 }} />
              </td>
              <td className={cx(styles.cell, styles.cellAlign)}>
                <div dangerouslySetInnerHTML={{ __html: table.title_column_2 }} />
              </td>
            </tr>
          </thead>
          <tbody>
            {table.tableRows &&
              table.tableRows.map(row => (
                <tr className={cx(styles.row, styles.rowLine)} key={row._key}>
                  <td className={styles.cell}>
                    {row.title}
                    <br />
                    <span className={styles.cellSmall}>{prepareParagraph(row.info)}</span>
                  </td>
                  <td className={cx(styles.cell, styles.cellIcon)}>{getIconByValue(row.column_1)}</td>
                  <td className={cx(styles.cell, styles.cellIcon)}>{getIconByValue(row.column_2)}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}
