import React, { useState, useEffect } from 'react'
import { urlWithSearchParamsHandler, srcSetProps, sanityImageUrl, prepareParagraph } from '../../../utils/format'
import SwiperObj, { FreeMode, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { Link, navigate } from 'gatsby'
import cx from 'classnames'
import styles from './style.module.sass'

const MainImage = ({ info, activeImage, changeImage, sliderSettings }) => {
  if (info.images.length === 1) {
    return (
      <img
        className={styles.imageSrc}
        {...srcSetProps(sanityImageUrl(activeImage.image))}
        alt={activeImage.image?.caption}
      />
    )
  }

  return (
    <div>
      <Swiper {...sliderSettings}>
        {info.images &&
          info.images.map(image => (
            <SwiperSlide className={styles.sliderItem}>
              <img
                className={styles.sliderItemImg}
                {...srcSetProps(sanityImageUrl(image.image))}
                alt={image.image?.caption}
              />
            </SwiperSlide>
          ))}
      </Swiper>

      <div className={styles.imageWrap}>
        <div className={styles.images}>
          {info.images &&
            info.images.map(image => (
              <div
                className={cx(styles.imagesItem, {
                  [styles.imagesItemActive]: activeImage === image,
                })}
                key={image._key}
                onClick={() => changeImage(image)}
              >
                <img
                  className={styles.imagesItemSrc}
                  {...srcSetProps(sanityImageUrl(image.image))}
                  alt={image.image?.caption}
                />
              </div>
            ))}
        </div>
        <div className={styles.imageBox}>
          <img
            className={styles.imageSrc}
            {...srcSetProps(sanityImageUrl(activeImage.image))}
            alt={activeImage.image?.caption}
          />
        </div>
      </div>
    </div>
  )
}

export default ({ info, headerDisable }) => {
  if (!info) return null

  SwiperObj.use([FreeMode, Mousewheel])

  const sliderSettings = {
    spaceBetween: 16,
    slidesPerView: 1.3,
    freeMode: {
      enabled: true,
      momentumVelocityRatio: 0.5,
    },
    mousewheel: {
      releaseOnEdges: true,
      sensitivity: 0.5,
      forceToAxis: true,
    },
    className: styles.slider,
  }

  const [activeImage, setActiveImage] = useState(info.images?.[0])
  const [safetyModalShow, setSafetyModalShow] = useState(false)
  const changeImage = image => {
    setActiveImage(image)
  }

  useEffect(() => {
    gsap.registerPlugin(ScrollToPlugin)
  }, [])

  const reviewsClickHandler = (path) => {
    if (path === '#reviews') {
      const reviewsElement = document.getElementById('reviews')
      if (reviewsElement) {
        gsap.to(window, {
          duration: 2,
          scrollTo: document.getElementById('reviews')
        })
      }
    } else {
      navigate(path)
    }
  }
  
  const openSafetyModalHandler = () => {
    setSafetyModalShow(true)
  }

  const closeSafetyModalHandler = () => {
    setSafetyModalShow(false)
  }

  return (
    <div
      className={cx(styles.info, {
        [styles.infoMultiple]: info.images?.length > 1,
        [styles.infoNoHeader]: headerDisable
      })}
    >
      <div className={styles.wrap}>
        <div className={styles.image}>
          {activeImage && (
            <MainImage
              info={info}
              activeImage={activeImage}
              changeImage={changeImage}
              sliderSettings={sliderSettings}
            />
          )}
        </div>

        <div className={styles.text}>
          {info.motto && <span className={styles.motto}>{info.motto}</span>}
          <h1 className={styles.title}>{info.title}</h1>
          {info.rating?.url && (
            <div className={styles.rating} onClick={() => reviewsClickHandler(info.rating.url)}>
              {info.rating.starsImage && <img className={styles.ratingImage} src={sanityImageUrl(info.rating.starsImage)} alt={info.rating?.text}/>}
              {info.rating.text && <p className={styles.ratingText}>{info.rating.text}</p>}
            </div>
          )} 
          <ul className={styles.list}>
            {info.benefits &&
              info.benefits.map((item, index) => (
                <li className={styles.listItem} key={index}>
                  {prepareParagraph(item)}
                </li>
              ))}
          </ul>

          {!info.ctaDisabled && (
            <a href={info.buttonUrl} onClick={urlWithSearchParamsHandler} className={styles.link}>
              {info.buttonText}
            </a>
          )}

          {info.importantSafetyModal?.modalText && (
            <>
              <div className={styles.safetyModalLink} onClick={openSafetyModalHandler}>ⓘ <span>{info.importantSafetyModal.modalText}</span></div>
              {safetyModalShow && (
                <div className={styles.safetyModal}>
                  <div className={styles.safetyModalBg} onClick={closeSafetyModalHandler} />
                  <div className={styles.safetyModalWrap}>
                    <div className={styles.safetyModalBox}>
                      <div className={styles.safetyModalClose} onClick={closeSafetyModalHandler} />
                      <p className={styles.safetyModalTitle}>{info.importantSafetyModal.modalTitle}</p>
                      <div className={styles.safetyModalContent} dangerouslySetInnerHTML={{ __html: info.importantSafetyModal.modalContent }} />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}
